import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardProps,
  Chip,
  Grid,
  Stack,
  SvgIconProps,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
// ICONS
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import { getPackageState } from '../../../store/PackageSlice';
// COMPONENTS
import PlanCard from '../components/PlanCard';
// CONSTANTS
import { flexiblePlans, SubjectColors } from '../../../constants';

interface IAccordianItem {
  key: string;
  title: string;
  description: string;
}

interface IState {
  selectedAccordianItem: string | undefined;
}

// STYLES
const cardContainerStyles: SxProps<Theme> = {
  backgroundColor: { xs: '#fff', sm: '#f9f9f9' },
  borderRadius: '7px',
  padding: '15px',
  display: {
    xs: 'none',
    sm: 'none',
    md: 'none',
    lg: 'block',
  },
};

const tableHeaderCellStyles: React.CSSProperties = {
  borderRadius: '4px',
  textAlign: 'center',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: '100px',
};

const mobileTableHeaderCellStyles = {
  ...tableHeaderCellStyles,
  minHeight: '80px',
};

const tableHeaderCellChipStyles: React.CSSProperties = {
  borderRadius: '4px',
  backgroundColor: '#fff',
  color: '#000',
  marginTop: '3px',
  fontWeight: 500,
};

const tableContentIconProps: SvgIconProps = {
  style: {
    width: '25px',
    height: '25px',
    color: '#57cc56',
  },
};

const flexiblePlanCardStyles: CardProps = {
  elevation: 3,
  sx: {
    borderRadius: '7px',
    height: '100%',
    minHeight: '750px',
    minWidth: {
      xs: '300px',
      sm: '300px',
      md: '300px',
      lg: 'unset',
    },
    marginLeft: {
      xs: '15px',
      sm: '15px',
      md: '15px',
      lg: 'unset',
    },
    backgroundColor: {
      xs: '#fff',
      sm: '#fff',
      md: '#fff',
      lg: 'transparent',
    },
    boxShadow: {
      lg: 'none',
    },
  },
};

const cardMobileContainerStyles: SxProps<Theme> = {
  flexWrap: 'nowrap',
  overflowX: 'auto',
  padding: '30px 0',
  backgroundColor: '#f9f9f9',
  display: {
    xs: 'flex',
    sm: 'flex',
    md: 'flex',
    lg: 'none',
  },
};

const mobileCardStyles: SxProps<Theme> = {
  minWidth: '300px',
  marginLeft: '15px',
};

const noBorderStyles: React.CSSProperties = { borderBottom: 'none' };

const tableContentCellStyles: SxProps<Theme> = {
  color: 'success.main',
  textAlign: 'center',
  fontWeight: 500,
  fontSize: '20px',
};

const tableContentTitleStyles: SxProps<Theme> = {
  ...tableContentCellStyles,
  color: '#000',
  fontSize: '14px',
  textAlign: 'start',
  fontWeight: 400,
};

// DATA
const PlanFeatureData = [
  {
    title: 'Number of Subjects',
    improve: '2',
    succeed: '3',
    excel: '4',
  },
  {
    title: 'Lesson Recordings',
    improve: <CheckIcon {...tableContentIconProps} />,
    succeed: <CheckIcon {...tableContentIconProps} />,
    excel: <CheckIcon {...tableContentIconProps} />,
  },
  {
    title: 'Progress Tracker',
    improve: <CheckIcon {...tableContentIconProps} />,
    succeed: <CheckIcon {...tableContentIconProps} />,
    excel: <CheckIcon {...tableContentIconProps} />,
  },
  {
    title: 'Feedback from Tutor',
    improve: <CheckIcon {...tableContentIconProps} />,
    succeed: <CheckIcon {...tableContentIconProps} />,
    excel: <CheckIcon {...tableContentIconProps} />,
  },
  {
    title: 'Chat with Tutor',
    improve: <CheckIcon {...tableContentIconProps} />,
    succeed: <CheckIcon {...tableContentIconProps} />,
    excel: <CheckIcon {...tableContentIconProps} />,
  },
  {
    title: 'Free New Subject Trial',
    improve: '2',
    succeed: '3',
    excel: '4',
  },
  {
    title: 'Primary + Substitute Tutor',
    improve: <CheckIcon {...tableContentIconProps} />,
    succeed: <CheckIcon {...tableContentIconProps} />,
    excel: <CheckIcon {...tableContentIconProps} />,
  },
  {
    title: 'Dedicated Account Manager',
    improve: <CheckIcon {...tableContentIconProps} />,
    succeed: <CheckIcon {...tableContentIconProps} />,
    excel: <CheckIcon {...tableContentIconProps} />,
  },
  {
    title: 'Past Papers',
    improve: <CloseIcon color="error" />,
    succeed: <CheckIcon {...tableContentIconProps} />,
    excel: <CheckIcon {...tableContentIconProps} />,
  },
];

const AccordianData: IAccordianItem[] = [
  {
    key: 'ac-1',
    title: 'How to enter promo codes',
    description:
      '- Once you have selected the package and click on Buy Now, you will see a pop up screen where you can enter promo codes',
  },
  {
    key: 'ac-2',
    title: 'How long are my credits valid?',
    description:
      '- 15 hours and 30 hours packages are valid up to 6 months and the 60 hours package is valid up to 1 year.',
  },
  {
    key: 'ac-3',
    title: 'What are the available payment options?',
    description:
      '- Debit/Credit cards\n- PayNow (SG customers only) - PayNow Proxy (UEN): 201813208R\n- Bank Transfer can be made to the below account – International transfers may have larger fees applicable (All bank fees need to be borne by the customer)\n\nBank: DBS\nAccount Number/IBAN: 0749031468\nAccount Name : Tutopiya Pte. Ltd\nSwift Code: DBSSSGSG Bank\nCode: 7171\nBranch Code: 074\nBranch: DBS Suntec City, Singapore',
  },
];

// VIEWS
const RENDER_FLEXIBLE_PLANS_FOR = (
  <Card {...flexiblePlanCardStyles}>
    <CardContent>
      <div style={{ marginBottom: '30px' }}>
        <Typography variant="h6" fontWeight={600}>
          Flexible Plans for
        </Typography>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Stack direction="row" alignItems="center">
          <div style={{ marginRight: '10px' }}>
            <DoneIcon color="secondary" />
          </div>
          <div>
            <Typography fontWeight={500}>No Monthly Commitment</Typography>
          </div>
        </Stack>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Stack direction="row" alignItems="center">
          <div style={{ marginRight: '10px' }}>
            <DoneIcon color="secondary" />
          </div>
          <div>
            <Typography fontWeight={500}>On-Demand Learning</Typography>
          </div>
        </Stack>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Stack direction="row" alignItems="center">
          <div style={{ marginRight: '10px' }}>
            <DoneIcon color="secondary" />
          </div>
          <div>
            <Typography fontWeight={500}>Learn at Your Own Pace</Typography>
          </div>
        </Stack>
      </div>
    </CardContent>
  </Card>
);

const FlexiblePlansTab: React.FC = () => {
  // HOOKS
  const packageState = useAppSelector(getPackageState);

  // LOCAL STATES
  const [state, setState] = useState<IState>({
    selectedAccordianItem: undefined,
  });

  const flexiblePlanList = flexiblePlans.map((flexiblePlan) => {
    const newFlexiblePlan = { ...flexiblePlan };

    if (
      packageState.packages &&
      packageState.packages &&
      packageState.packages[flexiblePlan.key]
    ) {
      const _currentPackage = packageState.packages[flexiblePlan.key];

      newFlexiblePlan.price = Number(_currentPackage.price);
      newFlexiblePlan.currency = _currentPackage.currency;

      const _numberPrice = Number(_currentPackage.price);
      const _numberHour = Number(flexiblePlan.hours);
      const _rate = parseFloat((_numberPrice / _numberHour).toFixed(2));

      newFlexiblePlan.rate = _rate;

      if (_currentPackage.registrationFee) {
        newFlexiblePlan.registrationFee = _currentPackage.registrationFee;
      }
    }

    return newFlexiblePlan;
  });

  // METHODS
  const handleAccordianChange = (accordianDataItem: IAccordianItem) => {
    setState((prevState) => ({
      ...prevState,
      selectedAccordianItem:
        prevState.selectedAccordianItem === accordianDataItem.key
          ? undefined
          : accordianDataItem.key,
    }));
  };

  return (
    <div>
      {/* DESKTOP PLAN VIEW */}
      <Box sx={cardContainerStyles}>
        <Grid container spacing={2} sx={{ maxWidth: { xl: '1400px' } }}>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            {RENDER_FLEXIBLE_PLANS_FOR}
          </Grid>
          {flexiblePlanList.map((flexiblePlanListItem, index) => (
            <Grid key={index} item xs={12} sm={4} md={4} lg={3}>
              <PlanCard plan={flexiblePlanListItem} />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* MOBILE PLAN VIEW */}
      <Box sx={cardMobileContainerStyles}>
        {RENDER_FLEXIBLE_PLANS_FOR}
        {flexiblePlanList.map((flexiblePlanListItem, index) => {
          const _style: CardProps['sx'] = {
            ...mobileCardStyles,
            marginRight: '0',
          };
          if (index === flexiblePlanList.length - 1) {
            _style.marginRight = '15px';
          }
          return (
            <PlanCard
              key={index}
              plan={flexiblePlanListItem}
              cardStyles={_style}
            />
          );
        })}
      </Box>

      <div style={{ margin: '50px 0' }}>
        <Stack direction="column" alignItems="center">
          <Typography variant="h5" fontWeight={500} textAlign="center">
            Compare plans in detail
          </Typography>
          <ArrowDownwardIcon fontSize="large" style={{ color: '#000' }} />
        </Stack>
      </div>

      {/* DESKTOP PLAN DETAIL */}
      <Box sx={{ mb: '50px', display: { xs: 'none', lg: 'block' } }}>
        <TableContainer>
          <Table aria-label="billing-table">
            <TableHead>
              <TableRow>
                <TableCell style={noBorderStyles}>
                  <span>&nbsp;</span>
                </TableCell>
                <TableCell style={noBorderStyles}>
                  <div
                    style={{
                      ...tableHeaderCellStyles,
                      background: SubjectColors[1],
                    }}
                  >
                    <Typography variant="h5" fontWeight={500}>
                      IMPROVE
                    </Typography>
                  </div>
                </TableCell>
                <TableCell style={noBorderStyles}>
                  <div
                    style={{
                      ...tableHeaderCellStyles,
                      background: SubjectColors[4],
                    }}
                  >
                    <Typography variant="h5" fontWeight={500}>
                      SUCCEED
                    </Typography>
                    <Chip
                      size="small"
                      label="MOST POPULAR"
                      style={tableHeaderCellChipStyles}
                    />
                  </div>
                </TableCell>
                <TableCell style={noBorderStyles}>
                  <div
                    style={{
                      ...tableHeaderCellStyles,
                      background: SubjectColors[0],
                    }}
                  >
                    <Typography variant="h5" fontWeight={500}>
                      EXCEL
                    </Typography>
                    <Chip
                      size="small"
                      label="BEST VALUE"
                      style={tableHeaderCellChipStyles}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {PlanFeatureData.map((featureData, index) => (
                <TableRow key={index} hover>
                  <TableCell sx={tableContentTitleStyles}>
                    {featureData.title}
                  </TableCell>
                  <TableCell sx={tableContentCellStyles}>
                    {featureData.improve}
                  </TableCell>
                  <TableCell sx={tableContentCellStyles}>
                    {featureData.succeed}
                  </TableCell>
                  <TableCell sx={tableContentCellStyles}>
                    {featureData.excel}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* MOBILE PLAN DETAIL */}
      <Box
        sx={{
          mb: '50px',
          display: { xs: 'block', lg: 'none' },
          p: '15px',
        }}
      >
        <div style={{ marginBottom: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div
                style={{
                  ...mobileTableHeaderCellStyles,
                  background: SubjectColors[1],
                }}
              >
                <Typography fontWeight={500}>Improve</Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  ...mobileTableHeaderCellStyles,
                  background: SubjectColors[4],
                }}
              >
                <Typography fontWeight={500}>Succees</Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  ...mobileTableHeaderCellStyles,
                  background: SubjectColors[0],
                }}
              >
                <Typography fontWeight={500}>Excel</Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          {PlanFeatureData.map((planFeature, index) => (
            <div
              key={index}
              style={{
                marginBottom: '15px',
                borderBottom: '1px solid lightgray',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={500}>{planFeature.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography
                        textAlign="center"
                        color="success.main"
                        fontSize="18px"
                      >
                        {planFeature.improve}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        textAlign="center"
                        color="success.main"
                        fontSize="18px"
                      >
                        {planFeature.succeed}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        textAlign="center"
                        color="success.main"
                        fontSize="18px"
                      >
                        {planFeature.excel}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      </Box>

      <div style={{ margin: '50px 0' }}>
        <Stack direction="column" alignItems="center">
          <Typography variant="h5" fontWeight={500} textAlign="center">
            Frequently Asked Questions (FAQs)
          </Typography>
          <ArrowDownwardIcon fontSize="large" style={{ color: '#000' }} />
        </Stack>
      </div>

      <div style={{ marginBottom: '30px' }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          flexDirection={{
            xs: 'column-reverse',
            sm: 'column-reverse',
            md: 'column-reverse',
            lg: 'row',
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <div>
              {AccordianData.map((accordianDataItem, index) => {
                const _selected =
                  accordianDataItem.key === state.selectedAccordianItem;
                return (
                  <Accordion
                    key={index}
                    expanded={_selected}
                    onChange={() => handleAccordianChange(accordianDataItem)}
                    elevation={0}
                    style={{
                      border: '1px solid rgba(224, 224, 224, 1)',
                      margin: '0',
                    }}
                  >
                    <AccordionSummary
                      id={`${accordianDataItem.key}`}
                      aria-controls={`${accordianDataItem.key}-content`}
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography fontWeight={500}>
                        {accordianDataItem.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        color="secondary"
                        fontWeight={500}
                        variant="subtitle2"
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {accordianDataItem.description}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div>
              <img
                src="/images/objects/billing-2-men.png"
                alt="accordian-img"
                style={{ width: '100%' }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default FlexiblePlansTab;
