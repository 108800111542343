import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  SxProps,
  Theme,
  Grid,
  Card,
  CardProps,
  CardContent,
  Typography,
  Stack,
  CardActionArea,
  Collapse,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

import { weeklyPlans } from '../../../constants';
import { useAppSelector } from '../../../hooks';
import { getPackageState } from '../../../store/PackageSlice';
import { getUserState } from '../../../store/UserSlice';
import { isMobileScreen } from '../../../helper';
import SubjectsCountModal from '../modals/SubjectsCountModal';
import HoursCountModal from '../modals/HoursCountModal';
import WeeklyPlanCard from '../components/WeeklyPlanCard';
import MonthlyPlanSubscriptionSwitcher from '../components/MonthlyPlanSubscriptionSwitcher';

export type TSubscriptionType =
  | 'upfront'
  | 'monthly'
  | 'instalment'
  | 'flexible';

interface IState {
  selectedSubjectsCount: number;
  isSubjectsCountModalOpen: boolean;
  selectedHourCount: number;
  isHoursCountModalOpen: boolean;
  subscriptionType: TSubscriptionType;
}

// STYLES
const cardContainerStyles: SxProps<Theme> = {
  backgroundColor: { xs: '#fff', sm: '#f9f9f9' },
  borderRadius: '7px',
  padding: '15px',
  display: {
    xs: 'none',
    sm: 'none',
    md: 'none',
    lg: 'block',
  },
};

const flexiblePlanCardStyles: CardProps = {
  elevation: 3,
  sx: {
    borderRadius: '7px',
    height: '100%',
    minHeight: '750px',
    minWidth: {
      xs: '300px',
      sm: '300px',
      md: '300px',
      lg: 'unset',
    },
    marginLeft: {
      xs: '15px',
      sm: '15px',
      md: '15px',
      lg: 'unset',
    },
    backgroundColor: {
      xs: '#fff',
      sm: '#fff',
      md: '#fff',
      lg: 'transparent',
    },
    boxShadow: {
      lg: 'none',
    },
  },
};

const gridContainerStyles: SxProps<Theme> = {
  backgroundColor: { xs: '#fff', sm: '#f9f9f9' },
  padding: '15px',
  marginBottom: '30px',
  borderRadius: '7px',
};

const cardMobileContainerStyles: SxProps<Theme> = {
  alignItems: 'center',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  padding: '30px 0',
  backgroundColor: '#f9f9f9',
  display: {
    xs: 'flex',
    sm: 'flex',
    md: 'flex',
    lg: 'none',
  },
};

const mobileCardStyles: SxProps<Theme> = {
  minWidth: '300px',
  marginLeft: '15px',
  minHeight: '750px',
};

// DATA
export const TOTAL_SUBJECTS_COUNT = 7;

export const HOUR_LIST = [60, 90, 120, 180, 240, 300, 360];

const initialState: IState = {
  selectedSubjectsCount: 1,
  selectedHourCount: 60,
  isSubjectsCountModalOpen: false,
  isHoursCountModalOpen: false,
  subscriptionType: 'upfront',
};

const RENDER_GRID_BOX = (
  title: string,
  selected = false,
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined = undefined
) => (
  <Card
    elevation={0}
    sx={{ backgroundColor: selected ? '#07b9aa' : '#cfd9e0' }}
  >
    <CardActionArea onClick={onClick}>
      <CardContent style={{ paddingBottom: '16px' }}>
        <Typography
          fontWeight={600}
          textAlign="center"
          className="ellipsis-text"
          color={selected ? '#fff' : '#000'}
        >
          {title}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

const WeeklyPlansTab: React.FC = () => {
  const location = useLocation();

  // Store
  const { user } = useAppSelector(getUserState);
  const { weeklyPlanPrices } = useAppSelector(getPackageState);

  // State
  const [state, setState] = useState(initialState);

  const isMobile = isMobileScreen();
  const urlParams = new URLSearchParams(location.search);
  const {
    selectedSubjectsCount,
    selectedHourCount,
    isHoursCountModalOpen,
    isSubjectsCountModalOpen,
  } = state;

  let planList = weeklyPlans;

  if (weeklyPlanPrices && Object.keys(weeklyPlanPrices).length > 0) {
    planList = weeklyPlans.map((item) => {
      const newWeeklyPlan = { ...item };

      if (weeklyPlanPrices && weeklyPlanPrices[item.key]) {
        const _currentPackage = weeklyPlanPrices[item.key];

        newWeeklyPlan.rate = _currentPackage.price
          ? _currentPackage.price.toString()
          : '0';

        newWeeklyPlan.currency = _currentPackage.currency || 'SGD';

        if (_currentPackage.registrationFee) {
          newWeeklyPlan.registrationFee = _currentPackage.registrationFee;
        }
      }

      return newWeeklyPlan;
    });
  }

  const monthlyPlansForOptions = [
    'Consistent Learning & Progress',
    'Reduces Exam Stress',
    'Leads To Better Results',
    'Inculcates Good Study Habits',
    'Most Popular And Affordable',
  ];

  if (state.subscriptionType === 'upfront') {
    monthlyPlansForOptions.push('Receive discounts');
  }

  useEffect(() => {
    if (urlParams.get('payment') === 'instalment') {
      setState((prevState) => ({
        ...prevState,
        subscriptionType: 'instalment',
      }));
    }
  }, []);

  const toggleSubjectsCountModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSubjectsCountModalOpen: !prevState.isSubjectsCountModalOpen,
    }));
  };

  const toggleHoursCountModal = () => {
    setState((prevState) => ({
      ...prevState,
      isHoursCountModalOpen: !prevState.isHoursCountModalOpen,
    }));
  };

  const handleSubjectCountChange = (count: number) => {
    setState((prevState) => ({
      ...prevState,
      selectedSubjectsCount: count,
      selectedHourCount: count * 60,
    }));
  };

  const handleHourCountChange = (count: number) => {
    setState((prevState) => ({
      ...prevState,
      selectedHourCount: count,
    }));
  };

  const toggleSubscription = () => {
    setState((prevState) => ({
      ...prevState,
      subscriptionType:
        prevState.subscriptionType === 'monthly' ? 'upfront' : 'monthly',
    }));
  };

  // RENDER
  const RENDER_FLEXIBLE_PLANS_FOR = (
    <Card {...flexiblePlanCardStyles}>
      <CardContent>
        <div style={{ marginBottom: '30px' }}>
          <Typography variant="h6" fontWeight={600}>
            Monthly Plans for
          </Typography>
        </div>
        {monthlyPlansForOptions.map((item, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <Stack direction="row" alignItems="center">
              <div style={{ marginRight: '10px' }}>
                <DoneIcon color="secondary" />
              </div>
              <div>
                <Typography fontWeight={500}>{item}</Typography>
              </div>
            </Stack>
          </div>
        ))}
      </CardContent>
    </Card>
  );

  const RENDER_GRID_SECTION = () => {
    let _max = 5;
    if (isMobile) _max = 3;

    const _subjects: number[] = [];
    for (let i = 1; i <= _max; i += 1) {
      _subjects.push(i);
    }

    const _hours: number[] = [];

    for (let i = 0; i < _max; i += 1) {
      _hours.push(HOUR_LIST[i] * selectedSubjectsCount);
    }

    return (
      <Box sx={gridContainerStyles}>
        <Grid
          container
          spacing={2}
          columnSpacing={{ lg: 2, xl: 6 }}
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <div style={{ marginBottom: '10px' }}>
              <Typography fontWeight={600}>How many subjects?</Typography>
            </div>
            <div>
              <Grid container alignItems="center" spacing={2}>
                {_subjects.map((item, index) => {
                  const isSelected = selectedSubjectsCount === item;
                  return (
                    <Grid key={index} item xs={3} sm={2} md={2} lg={2}>
                      {RENDER_GRID_BOX(item.toString(), isSelected, () =>
                        handleSubjectCountChange(item)
                      )}
                    </Grid>
                  );
                })}
                <Grid item xs={3} sm={2} md={2} lg={2}>
                  {RENDER_GRID_BOX(
                    isMobile ? '+' : 'More',
                    selectedSubjectsCount > _subjects.length,
                    () => toggleSubjectsCountModal()
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <div style={{ marginBottom: '10px' }}>
              <Typography fontWeight={600}>
                How many hours per week in total?
              </Typography>
            </div>
            <div>
              <Grid container alignItems="center" spacing={2}>
                {_hours.map((item, index) => {
                  const isSelected = selectedHourCount === item;
                  const _label = parseFloat((item / 60).toFixed(2)).toString();
                  return (
                    <Grid key={index} item xs={3} sm={2} md={2} lg={2}>
                      {RENDER_GRID_BOX(_label, isSelected, () =>
                        handleHourCountChange(item)
                      )}
                    </Grid>
                  );
                })}
                <Grid item xs={3} sm={2} md={2} lg={2}>
                  {RENDER_GRID_BOX(
                    isMobile ? '+' : 'Custom',
                    _hours.every((opt) => opt < selectedHourCount),
                    () => toggleHoursCountModal()
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <div>
      <Collapse
        in={
          state.subscriptionType !== 'instalment' &&
          user &&
          user.allowMonthlyPlanTab === true
        }
      >
        <div style={{ marginBottom: '30px' }}>
          <MonthlyPlanSubscriptionSwitcher
            isChecked={state.subscriptionType === 'monthly'}
            toggle={toggleSubscription}
          />
        </div>
      </Collapse>
      {/* GRID VIEWS */}
      {RENDER_GRID_SECTION()}

      {/* DESKTOP PLAN VIEW */}
      <Box sx={cardContainerStyles}>
        <Grid container spacing={2} sx={{ maxWidth: { xl: '1400px' } }}>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            {RENDER_FLEXIBLE_PLANS_FOR}
          </Grid>
          {planList.map((weeklyPlan, index) => (
            <Grid key={index} item xs={12} sm={4} md={4} lg={3}>
              <WeeklyPlanCard
                plan={weeklyPlan}
                subscriptionType={state.subscriptionType}
                selectedHourCount={selectedHourCount}
                selectedSubjectsCount={selectedSubjectsCount}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* MOBILE PLAN VIEW */}
      <Box sx={cardMobileContainerStyles} className="custom-scroll-h4">
        {RENDER_FLEXIBLE_PLANS_FOR}
        {planList.map((weeklyPlan, index) => {
          const _style: SxProps<Theme> = {
            ...mobileCardStyles,
            marginRight: undefined,
          };
          if (index === 2) {
            _style.marginRight = '15px';
          }

          return (
            <WeeklyPlanCard
              key={index}
              cardStyles={_style}
              plan={weeklyPlan}
              subscriptionType={state.subscriptionType}
              selectedHourCount={selectedHourCount}
              selectedSubjectsCount={selectedSubjectsCount}
            />
          );
        })}
      </Box>

      {/* MODALS */}
      <SubjectsCountModal
        open={isSubjectsCountModalOpen}
        onClose={toggleSubjectsCountModal}
        selectedSubjectCount={selectedSubjectsCount}
        onChange={(count) => {
          handleSubjectCountChange(count);
          toggleSubjectsCountModal();
        }}
      />

      {isHoursCountModalOpen && (
        <HoursCountModal
          open={isHoursCountModalOpen}
          onClose={toggleHoursCountModal}
          selectedHourCount={selectedHourCount}
          selectedSubjectsCount={selectedSubjectsCount}
          onChange={(count) => {
            handleHourCountChange(count);
            toggleHoursCountModal();
          }}
        />
      )}
    </div>
  );
};

export default WeeklyPlansTab;
