export type TFlexiblePlanKey =
  | 'regular_plan'
  | 'premium_plan'
  | 'platinum_plan';
export type TFlexiblePlanId = 'Regular Plan' | 'Premium Plan' | 'Platinum Plan';
export type TFlexiblePlanTitle = 'Standard' | 'Improve' | 'Succeed' | 'Excel';

export type TWeeklyPlanKey = 'bronzePlan' | 'silverPlan' | 'goldPlan';
export type TWeeklyPlanId = 'Bronze Plan' | 'Silver Plan' | 'Gold Plan';
export type TWeeklyPlanTitle = 'Bronze' | 'Silver' | 'Gold';

export type TPlanKey = TFlexiblePlanKey | TWeeklyPlanKey;
export type TPlanId = TFlexiblePlanId | TWeeklyPlanId;
export type TPlanTitle = TFlexiblePlanTitle | TWeeklyPlanTitle;

export type TPlanType = 'weekly' | 'flexible';

export interface IFlexiblePlan {
  key: TFlexiblePlanKey;
  id: TFlexiblePlanId;
  title: TFlexiblePlanTitle;
  currency: string;
  rate: number;
  price: number;
  hours: 15 | 30 | 60;
  labelText?: string;
  features: string[];
  registrationFee?: number;
}

export interface IWeeklyPlan {
  key: 'bronzePlan' | 'silverPlan' | 'goldPlan';
  id: 'Bronze Plan' | 'Silver Plan' | 'Gold Plan';
  title: 'Bronze' | 'Silver' | 'Gold';
  durationTitle: '3 Months' | '6 Months' | '1 Year';
  currency: string;
  rate: string;
  weeks: 12 | 24 | 48;
  features: string[];
  registrationFee?: number;
}

export const flexiblePlans: IFlexiblePlan[] = [
  {
    key: 'regular_plan',
    id: 'Regular Plan',
    title: 'Improve',
    currency: '',
    rate: 0,
    price: 0,
    hours: 15,
    features: [
      'For up to 2 Subjects',
      'Lesson Recordings',
      'Progress Tracker',
      'Feedback from Tutor',
      'Chat with Tutor',
      'Primary + Substitute Tutor',
      'Dedicated Account Manager',
      'Valid for 6 months',
    ],
  },
  {
    key: 'premium_plan',
    id: 'Premium Plan',
    title: 'Succeed',
    currency: '',
    rate: 0,
    price: 0,
    hours: 30,
    labelText: 'MOST POPULAR',
    features: [
      'For up to 3 Subjects',
      'Lesson Recordings',
      'Progress Tracker',
      'Feedback from Tutor',
      'Chat with Tutor',
      'Primary + Substitute Tutor',
      'Dedicated Account Manager',
      'Resource Library',
      'Valid for 6 months',
    ],
  },
  {
    key: 'platinum_plan',
    id: 'Platinum Plan',
    title: 'Excel',
    currency: '',
    rate: 0,
    price: 0,
    hours: 60,
    labelText: 'BEST VALUE',
    features: [
      'For up to 4 Subjects',
      'Lesson Recordings',
      'Progress Tracker',
      'Feedback from Tutor',
      'Chat with Tutor',
      'Primary + Substitute Tutor',
      'Dedicated Account Manager',
      'Resource Library',
      'Valid for 12 months',
      // 'Quiztopiya',
    ],
  },
];

export const weeklyPlans: IWeeklyPlan[] = [
  {
    key: 'bronzePlan',
    id: 'Bronze Plan',
    title: 'Bronze',
    durationTitle: '3 Months',
    currency: '',
    rate: '',
    weeks: 12,
    features: [
      'Lesson Recordings',
      'Progress Tracker',
      'Feedback from Tutor',
      'Chat with Tutor',
      'Primary + Substitute Tutor',
    ],
  },
  {
    key: 'silverPlan',
    id: 'Silver Plan',
    title: 'Silver',
    durationTitle: '6 Months',
    currency: '',
    rate: '',
    weeks: 24,
    features: [
      'Lesson Recordings',
      'Progress Tracker',
      'Feedback from Tutor',
      'Chat with Tutor',
      'Primary + Substitute Tutor',
      'Resource Library',
    ],
  },
  {
    key: 'goldPlan',
    id: 'Gold Plan',
    title: 'Gold',
    durationTitle: '1 Year',
    currency: '',
    rate: '',
    weeks: 48,
    features: [
      'Lesson Recordings',
      'Progress Tracker',
      'Feedback from Tutor',
      'Chat with Tutor',
      'Primary + Substitute Tutor',
      'Resource Library',
      // 'Quiztopiya',
    ],
  },
];

export const instalmentWeeksCount = 4;

export const subscriptionWeeks = 4;

export const weeklyPlanTotalSubjectsCount = 7;

export const weeklyPlanHourList = [60, 90, 120, 180, 240, 300, 360]; // in mins
